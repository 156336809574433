import { ref, reactive, onMounted, onBeforeMount, computed } from "vue";
import apiCall from "@/utils/api";
import { requestStatus, ACCOUNT_NOT_FOUND, otpStatus } from "@/utils/constants";
import { isOtpInvalid } from "@/utils/functions";
import store from "@/store/index.js";
import { USER_OTP_DATA, USER_DATA } from "@/store/actions/user.js";
import router from "@/router/index.js";
import { getHashedPassword } from "@/utils/functions";
import { AUTH_SUCCESS } from "@/store/actions/auth";
import { useOtpStatus } from "../otp/business_logic";
import { routes } from "@/router/routes";
import { countyIsKirinyaga } from "@/utils/vue_helpers";

export function useLogin() {
  function clearLoginDataOnMount() {
    onBeforeMount(() => {
      goTo.enterId();
    });

    onMounted(() => {
      userAuthenticated.value = false;
      loginModels.idNumber = "";
      loginModels.password = "";
      setOtpStatus(otpStatus.NOT_VALIDATED);
    });
  }

  const views = {
    ENTER_ID: 1,
    ENTER_PASSWORD: 2,
    OTP: 3,
  };
  const currentView = ref(views.ENTER_ID);
  const goTo = {
    enterId() {
      userExists.value = false;
      userAuthenticated.value = false;
      transitionClass.value = "slide-right";
      loginModels.password = "";
      currentView.value = views.ENTER_ID;
    },
    enterPassword() {
      userExists.value = true;
      userAuthenticated.value = false;
      transitionClass.value = "slide-left";
      currentView.value = views.ENTER_PASSWORD;
    },
    otp() {
      currentView.value = views.OTP;
    },
  };
  const isCurrentView = {
    enterId: computed(() => currentView.value === views.ENTER_ID),
    enterPassword: computed(() => currentView.value === views.ENTER_PASSWORD),
    otp: computed(() => currentView.value === views.OTP),
  };

  const transitionClass = ref("slide-left");

  //  const loginModels = reactive({
  //     idNumber: '123456',
  //     password: 'password',
  // })
  const loginModels = reactive({
    idNumber: "",
    password: "",
  });
  const hashedPassword = computed(() => {
    const password = loginModels.password;
    if (password !== null && password !== undefined) {
      const hashedPassword = getHashedPassword(loginModels.password);
      return hashedPassword;
    }
    return "";
  });

  const otpData = ref({
    phoneNumber: "",
    countryCode: "KE",
    email: "",
  });

  const userExists = ref(false);
  const userAuthenticated = ref(false);
  const userIdNotInSystem = ref(false);
  const authenticateUserRequestStatus = ref(requestStatus.NOT_SENT);
  const fetchUserMatchingIdRequestStatus = ref(requestStatus.NOT_SENT);

  function setOtpData({ phoneNumber, countryCode, emailAddress }) {
    otpData.phoneNumber = phoneNumber;
    otpData.countryCode = countryCode;
    otpData.emailAddress = emailAddress;
  }
  async function authenticateUser() {
    const requestData = {
      id_number: loginModels.idNumber,
      password: hashedPassword.value,
    };

    try {
      authenticateUserRequestStatus.value = requestStatus.SENDING;
      const response = await apiCall({
        url: "/api/client/authenticate",
        data: requestData,
        method: "POST",
      });
      userAuthenticated.value = true;
      authenticateUserRequestStatus.value = requestStatus.COMPLETE;
      goTo.otp();
    } catch (error) {
      console.log(error);
      authenticateUserRequestStatus.value = requestStatus.ERROR;
      const responseError = error.response;
      const errorCode = responseError.status;
      const errorText = responseError.data.error;
      const authHasError = doesAuthHaveError({ errorCode, errorText });
      if (authHasError) {
        authenticateUserRequestStatus.value = ACCOUNT_NOT_FOUND;
      }
      return false;
    }
  }
  function doesAuthHaveError({ errorCode, errorText }) {
    const statusCodeIs401 = errorCode === 401;
    const errorTextMatchesAuthError = errorText === ACCOUNT_NOT_FOUND;
    const authHasError = statusCodeIs401 && errorTextMatchesAuthError;
    if (authHasError) {
      return true;
    }
    return false;
  }

  const logUserInRequestStatus = ref(requestStatus.NOT_SENT);
  function addUserDataToStore(token) {
    const userData = {
      profile: {
        id_number: loginModels.idNumber,
      },
    };
    store.commit(USER_DATA, userData);
    store.commit(AUTH_SUCCESS, token);
  }
  const { setOtpStatus, otpInvalid, resetOtpStatusToInitial } = useOtpStatus();
  async function logUserIn(otp) {
    const requestData = {
      id_number: loginModels.idNumber,
      password: hashedPassword.value,
      county: process.env.VUE_APP_COUNTY,
      otp,
    };
    try {
      logUserInRequestStatus.value = requestStatus.SENDING;
      const response = await apiCall({
        url: "/api/client/login",
        data: requestData,
        method: "POST",
      });
      logUserInRequestStatus.value = requestStatus.COMPLETE;
      addUserDataToStore(response.token);
      setOtpStatus(otpStatus.OTP_VALID);
      router.push(routes.userAccountDetails);
    } catch (error) {
      console.log(error)
      logUserInRequestStatus.value = requestStatus.ERROR;
      const responseError = error.response;
      const errorCode = responseError.status;
      const errorText = responseError.data.error;
      const otpIsInvalid = isOtpInvalid({ errorCode, errorText });
      if (otpIsInvalid) {
        setOtpStatus(otpStatus.OTP_INVALID);
      }
    }
  }

  function goBackToLogin() {
    loginModels.password = "";
    resetOtpToSendStage();
    userAuthenticated.value = false;
  }

  const checkAccountRequestStatus = ref(requestStatus.NOT_SENT);
  async function checkAccount() {
    userIdNotInSystem.value = false;
    const requestData = {
      id_number: loginModels.idNumber,
    };
    try {
      checkAccountRequestStatus.value = requestStatus.SENDING;
      const response = await apiCall({
        url: "/api/client/check-account",
        data: requestData,
        method: "POST",
      });
      checkAccountRequestStatus.value = requestStatus.COMPLETE;
      handleCheckAccountResponse(response);
    } catch (error) {
      console.log(error);
      checkAccountRequestStatus.value = requestStatus.ERROR;
      throw error;
    }
  }

  function handleCheckAccountResponse(response) {
    const accountExists = response.status === 200;
    const accountDoesNotExist = response.status === 400;
    if (accountExists) {
      const accountActivated = response.accountActivated === true;
      if (accountActivated) {
        otpData.value = {
          phoneNumber: response.obscuredPhoneNumber,
          email: response.obscuredEmailAddress,
          idNumber: loginModels.idNumber,
        };
        store.commit(USER_OTP_DATA, otpData.value);
        if (countyIsKirinyaga.value) {
          goTo.otp();
        } else {
          goTo.enterPassword();
        }
      } else {
        response.idNumber = loginModels.idNumber;
        navigateToCreateAccountAsExistingUser(response);
      }
    } else if (accountDoesNotExist) {
      userIdNotInSystem.value = true;
    }
  }

  function navigateToCreateAccountAsExistingUser(existingUserData) {
    router.push({
      name: "CreateAccount",
      params: {
        activateAccountData: existingUserData,
      },
    });
  }

  return {
    loginModels,
    authenticateUserRequestStatus,
    logUserIn,
    authenticateUser,
    userAuthenticated,
    otpData,
    logUserInRequestStatus,
    clearLoginDataOnMount,
    transitionClass,
    isCurrentView,
    otpInvalid,
    checkAccount,
    checkAccountRequestStatus,
    userIdNotInSystem,
    goTo,
    resetOtpStatusToInitial,
  };
}
