var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pl-md-10"},[_c('div',{class:{
      'text-h6 primary--text mb-md-8 mt-4 mt-md-0': true,
      'text-center': _vm.$mobileBreakpoint,
    }},[_vm._v(" Login ")]),_c('transition',{attrs:{"name":_setup.transitionClass,"mode":"out-in"}},[(_setup.isCurrentView.enterId.value)?_c(_setup.EnterId,{key:"userNotIdentified",attrs:{"loginModels":_setup.loginModels,"checkAccountRequestStatus":_setup.checkAccountRequestStatus,"userIdNotInSystem":_setup.userIdNotInSystem},on:{"update:userIdNotInSystem":function($event){_setup.userIdNotInSystem=$event},"update:user-id-not-in-system":function($event){_setup.userIdNotInSystem=$event},"check-account":_setup.checkAccount}}):(_setup.isCurrentView.enterPassword.value)?_c(_setup.EnterPassword,{key:"userNotAuthenticated",attrs:{"loginModels":_setup.loginModels,"authenticateUserRequestStatus":_setup.authenticateUserRequestStatus},on:{"authenticate-user":_setup.authenticateUser,"back-clicked":_setup.goTo.enterId}}):_c('v-container',{key:"userAuthenticated",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-md-4"},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12"}},[_c('div',{class:{
              'text-body-2 text-md-h6 primary--text': true,
              'text-center': _vm.$mobileBreakpoint,
            }},[_vm._v(" Enter Verification Code to proceed ")])])],1),_c('v-row',[_c('v-col',{staticClass:"pr-2 pl-md-0",attrs:{"cols":"12"}},[_c(_setup.Otp,{attrs:{"phoneNumber":_setup.otpData.phoneNumber,"countryCode":_setup.otpData.countryCode,"emailAddress":_setup.otpData.email,"idNumber":_setup.loginModels.idNumber,"verifying":_setup.loggingUserIn,"usingIdNumber":"","otpInvalid":_setup.otpInvalid,"resetOtpStatusToInitialFn":_setup.resetOtpStatusToInitial,"sendOtpImmediately":_setup.countyIsKirinyaga},on:{"verifyOtpClicked":_setup.logUserIn}})],1)],1),_c('v-row',{staticClass:"mt-md-4",attrs:{"justify":"center","justify-md":"start"}},[_c('v-col',{staticClass:"pl-md-0",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"text-none",attrs:{"outlined":"","rounded":"","depressed":"","block":""},on:{"click":function($event){return _setup.goTo.enterId()}}},[_c('v-icon',{attrs:{"small":_vm.$mobileBreakpoint,"left":""}},[_vm._v("mdi-arrow-left")]),_c('span',{staticClass:"text-caption text-md-body-2"},[_vm._v("Back to Enter ID Number")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }