<script setup>
import { requestStatus, ACCOUNT_NOT_FOUND } from "@/utils/constants";
import LoadingButton from "@/components/utils/LoadingButton.vue";
import ResetPasswordLink from "../ResetPasswordLink.vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import { fieldRequired } from "@/utils/input_validation.js";
import { ref, computed } from "vue";

const props = defineProps({
  loginModels: {
    type: Object,
    default: null,
  },
  authenticateUserRequestStatus: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["authenticate-user"]);

const authenticatingUser = computed(() => {
  return props.authenticateUserRequestStatus === requestStatus.SENDING;
});

const accountNotFound = computed(() => {
  return props.authenticateUserRequestStatus === ACCOUNT_NOT_FOUND;
});

const form = ref(null);
async function onContinueClicked() {
  const formValid = form.value.validate();
  if (formValid) {
    emit("authenticate-user");
  }
}
</script>

<template>
  <v-container class="pa-md-0">
    <v-row class="mb-4 mb-md-8">
      <v-col cols="12">
        <div
          :class="{ 'primary--text': true, 'text-center': $mobileBreakpoint }"
        >
          Enter Password
        </div>
      </v-col>
    </v-row>

    <v-form @submit.prevent="onContinueClicked" ref="form">
      <v-row>
        <v-col cols="12" md="8">
          <PasswordInput
            label="Password"
            v-model="loginModels.password"
            :error="accountNotFound && !authenticatingUser"
            :rules="[fieldRequired]"
          />
        </v-col>
      </v-row>

      <v-row v-if="accountNotFound" dense justify="center" justify-md="start">
        <v-col cols="auto" md="12" class="d-flex align-center">
          <div class="error--text text-body-2 text-md-subtitle-2">
            Wrong password
          </div>
          <v-icon class="error--text ml-2">mdi-alert-circle</v-icon>
        </v-col>
      </v-row>

      <v-row v-if="$mobileBreakpoint" justify="center">
        <v-col cols="auto" :md="authenticatingUser ? '4' : 'auto'">
          <LoadingButton
            rounded
            block
            :class="{
              'button-background-secondary-1 white--text': !authenticatingUser,
            }"
            :loading="authenticatingUser"
            @click="onContinueClicked"
          >
            <div class="d-flex align-center">
              <span>Continue</span>
              <v-icon class="white--text" right>mdi-chevron-right</v-icon>
            </div>
            <template #loadingText> Verifying </template>
          </LoadingButton>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="8">
          <v-container fluid class="pa-0">
            <v-row no-gutters justify="center" justify-md="space-between">
              <v-col cols="auto" md="auto">
                <ResetPasswordLink class="ml-md-n2" />
              </v-col>

              <v-col v-if="!$mobileBreakpoint" cols="auto" :md="authenticatingUser ? '4' : 'auto'">
                <LoadingButton
                  rounded
                  block
                  :class="{
                    'button-background-secondary-1 white--text':
                      !authenticatingUser,
                  }"
                  :loading="authenticatingUser"
                  @click="onContinueClicked"
                >
                  <div class="d-flex align-center">
                    <span>Continue</span>
                    <v-icon class="white--text" right>mdi-chevron-right</v-icon>
                  </div>
                  <template #loadingText> Verifying </template>
                </LoadingButton>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row class="mt-md-10" justify="center" justify-md="start">
        <v-col cols="auto">
          <v-btn
            outlined
            rounded
            depressed
            block
            class="text-none"
            @click="$emit('back-clicked')"
          >
            <span class="text-caption text-md-body-2">Back</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
