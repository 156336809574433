<script setup>
import EnterId from "./EnterId.vue";
import EnterPassword from "./EnterPassword.vue";
import { fieldRequired } from "@/utils/input_validation.js";
import { useLogin } from "./business_logic";
import { requestStatus, ACCOUNT_NOT_FOUND } from "@/utils/constants";
import Otp from "../otp/index.vue";
import { stringNotEmpty } from "@/utils/functions";
import { computed, ref, onMounted } from "vue";
import { countyIsKirinyaga } from "@/utils/vue_helpers";

const {
  loginModels,
  authenticateUserRequestStatus,
  logUserIn,
  authenticateUser,
  userAuthenticated,
  otpData,
  logUserInRequestStatus,
  transitionClass,
  isCurrentView,
  otpInvalid,
  checkAccount,
  checkAccountRequestStatus,
  userIdNotInSystem,
  goTo,
  resetOtpStatusToInitial
} = useLogin();

const authenticatingUser = computed(() => {
  return authenticateUserRequestStatus.value === requestStatus.SENDING;
});

const loggingUserIn = computed(() => {
  return logUserInRequestStatus.value === requestStatus.SENDING;
});

const accountNotFound = computed(() => {
  return authenticateUserRequestStatus.value === ACCOUNT_NOT_FOUND;
});

const authenticationErrorPresent = computed(() => {
  return authenticateUserRequestStatus.value === requestStatus.ERROR;
});

const title = computed(() => {
  if (userAuthenticated.value === true) {
    return "Enter Verification Code";
  }
  return "Welcome, Login to access county services";
});

const form = ref(null);

function onLoginClicked() {
  const formValid = form.value.validate();
  if (formValid) {
    authenticateUser();
  }
}

async function onVerifyOtpClicked(otp) {
  await logUserIn(otp);
}
</script>

<template>
  <div class="pl-md-10">
    <div
      :class="{
        'text-h6 primary--text mb-md-8 mt-4 mt-md-0': true,
        'text-center': $mobileBreakpoint,
      }"
    >
      Login
    </div>
    <transition :name="transitionClass" mode="out-in">
      <EnterId
        v-if="isCurrentView.enterId.value"
        key="userNotIdentified"
        :loginModels="loginModels"
        :checkAccountRequestStatus="checkAccountRequestStatus"
        :userIdNotInSystem.sync="userIdNotInSystem"
        @check-account="checkAccount"
      />

      <EnterPassword
        v-else-if="isCurrentView.enterPassword.value"
        key="userNotAuthenticated"
        :loginModels="loginModels"
        :authenticateUserRequestStatus="authenticateUserRequestStatus"
        @authenticate-user="authenticateUser"
        @back-clicked="goTo.enterId"
      />

      <v-container v-else key="userAuthenticated" fluid class="">
        <v-row class="mb-md-4">
          <v-col cols="12" class="pl-0">
            <div
              :class="{
                'text-body-2 text-md-h6 primary--text': true,
                'text-center': $mobileBreakpoint,
              }"
            >
              Enter Verification Code to proceed
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pr-2 pl-md-0">
            <Otp
              :phoneNumber="otpData.phoneNumber"
              :countryCode="otpData.countryCode"
              :emailAddress="otpData.email"
              :idNumber="loginModels.idNumber"
              :verifying="loggingUserIn"
              usingIdNumber
              @verifyOtpClicked="logUserIn"
              :otpInvalid="otpInvalid"
              :resetOtpStatusToInitialFn="resetOtpStatusToInitial"
              :sendOtpImmediately="countyIsKirinyaga"
            />
          </v-col>
        </v-row>
        <v-row justify="center" justify-md="start" class="mt-md-4">
          <v-col cols="auto" class="pl-md-0">
            <v-btn
              outlined
              rounded
              depressed
              block
              class="text-none"
              @click="goTo.enterId()"
            >
              <v-icon :small="$mobileBreakpoint" left>mdi-arrow-left</v-icon>
              <span class="text-caption text-md-body-2"
                >Back to Enter ID Number</span
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </transition>
  </div>
</template>

<style scoped>
.to-login-enter-active,
.to-login-leave-active,
.to-verify-enter-active,
.to-verify-leave-active {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.to-verify-enter {
  transform: translateX(-20px);
  opacity: 0;
}

.to-verify-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.to-login-enter {
  transform: translateX(20px);
  opacity: 0;
}

.to-login-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
</style>
