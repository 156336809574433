<script setup>
import { requestStatus, ACCOUNT_NOT_FOUND } from "@/utils/constants";
import LoadingButton from "@/components/utils/LoadingButton.vue";
import LabelledField from "../../utils/LabelledField.vue";
import { fieldRequired } from "@/utils/input_validation.js";
import { routes } from "@/router/routes";
import { ref, computed } from "vue";

const props = defineProps({
  loginModels: {
    type: Object,
    default: null
  },
  checkAccountRequestStatus: {
    type: [String],
    default: ''
  },
  userIdNotInSystem: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['check-account', 'update:userIdNotInSystem'])

const form = ref(null);

const checkingAccount = computed(() => {
  return props.checkAccountRequestStatus === requestStatus.SENDING;
});

async function onContinueClicked() {
  emit('update:userIdNotInSystem', false)
  const formValid = form.value.validate();
  if (formValid) {
    emit('check-account')
  }
}

const redirectObjectWhenAccountNotFound = computed(() => {
  return routes.createAccount({ idNumber: props.loginModels.idNumber })
})
</script>

<template>
  <v-container class="pa-md-0">
    <v-row class="mb-4 mb-md-8" :no-gutters="$vuetify.breakpoint.smAndDown">
      <v-col cols="12" md="12">
        <div :class="{ 'primary--text mt-1': true, 'text-center': $mobileBreakpoint }">
          Enter your ID Number or Business Registration Number to continue
        </div>
      </v-col>
    </v-row>

    <v-form @submit.prevent.prevent="onContinueClicked" ref="form">
      <v-row>
        <v-col cols="12" md="6">
          <LabelledField label="ID Number / Business Registration Number" required>
            <v-text-field outlined dense prepend-inner-icon="mdi-account" v-model="loginModels.idNumber"
              :error="userIdNotInSystem && !checkingAccount" :rules="[fieldRequired]" hide-details="auto"
              class="text-field-background"></v-text-field>
          </LabelledField>
        </v-col>
      </v-row>

      <template v-if="userIdNotInSystem && !checkingAccount">
        <v-row no-gutters justify="start">
          <v-col cols="12" class="d-flex justify-center justify-md-start align-center">
            <div class="error--text text-caption text-md-subtitle-2 text-center text-md-left">
              Your ID / Business number could not be found
            </div>
            <v-icon v-if="!$mobileBreakpoint" class="error--text ml-1 ml-md-2">mdi-alert-circle</v-icon>
          </v-col>
        </v-row>

        <v-row justify="center" justify-md="start">
          <v-col cols="auto">
            <router-link :to="redirectObjectWhenAccountNotFound" class="secondary--text">
              <div class="text-body-2 text-md-body-2">
                Click here to create an account
              </div>
            </router-link>
          </v-col>
        </v-row>
      </template>

      <v-row :justify="$mobileBreakpoint ? 'center' : 'start'">
        <v-col cols="auto" md="4">
          <LoadingButton rounded block :class="{
            'button-background-secondary-1 white--text': !checkingAccount,
          }" :loading="checkingAccount" @click="onContinueClicked">
            <div class="d-flex align-center">
              <span>Continue</span>
              <v-icon class="white--text" right>mdi-chevron-right</v-icon>
            </div>
            <template #loadingText> Logging In </template>
          </LoadingButton>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>